.header-title-callback {
  text-align: center;
  text-transform: capitalize;
  font-weight: bold;
  color: #333;
  font-size: 2em;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.column-title {
  color: #333;
}

:where(.css-dev-only-do-not-override-1wwf28x).ant-table-wrapper .ant-table-thead >tr>th, :where(.css-dev-only-do-not-override-1wwf28x).ant-table-wrapper .ant-table-thead >tr>td {
  position: relative;
  color: rgba(0, 0, 0, 0.88);
  font-weight: bold;
  text-align: start;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.2s ease;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
.filter-container {
display: flex;
justify-content: flex-end;
margin-bottom: 16px;
}
